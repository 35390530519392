var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.isActive
    ? _c(
        "div",
        [
          _c("ejs-chiplist", {
            staticClass: "e-success",
            attrs: { text: _vm.$t("users.statuses.active") }
          })
        ],
        1
      )
    : _vm.isLockout
    ? _c(
        "div",
        [
          _c("ejs-chiplist", {
            staticClass: "e-warning",
            attrs: { text: _vm.$t("users.statuses.blocked") }
          })
        ],
        1
      )
    : _vm.isDisabled
    ? _c(
        "div",
        [
          _c("ejs-chiplist", {
            staticClass: "e-danger",
            attrs: { text: _vm.$t("users.statuses.disabled") }
          })
        ],
        1
      )
    : _vm.hasNotConfirmedEmail
    ? _c(
        "div",
        [
          _c("ejs-chiplist", {
            staticClass: "e-warning",
            attrs: { text: _vm.$t("users.statuses.notVerified") }
          })
        ],
        1
      )
    : _c(
        "div",
        [
          _c("ejs-chiplist", {
            staticClass: "e-warning",
            attrs: { text: _vm.$t("users.statuses.unknown") }
          })
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }